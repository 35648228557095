<template>
  <div
      v-if="!$route.meta.hasNoFooter"
      class="flex pt-40 pl-40 pr-40 pb-61 bg-faded-red"
  >
    <div class="w-1/2 font-sans-bold text-xl text-pure-white uppercase mr-34">
      {{ $t('view_footer.main_title_text') }}
    </div>
    <ul
        v-for="(footerItem, index) in navigationItems"
        :key="index"
        class="list-container list-reset w-1/4"
        :class="footerItem.classeNames"
    >
      <li class="font-sans-bold text-base text-pure-white leading-29 mb-22">
        {{ footerItem.titleText }}
      </li>
      <li
          v-for="(navItem, index) in footerItem.navItems"
          :key="index"
          :class="navItem.classeNames"
      >
        <RouterLink
            v-if="navItem.routeName"
            :to="{ name: navItem.routeName }"
            class="font-sans-regular text-base text-pure-white leading-29 no-underline"
        >
          {{ navItem.navText }}
        </RouterLink>
        <a
            v-else-if="navItem.externalLink"
            :href="navItem.externalLink"
            :target="navItem.openBlank"
            class="font-sans-regular text-base text-pure-white leading-29 no-underline"
        >
          {{ navItem.navText }}
        </a>
      </li>
    </ul>
  </div>
</template>


<script>
export default {
  computed: {
    navigationItems() {
      return [{
        titleText: 'Service',
        classeNames: ['mr-34'],
        navItems: [{
        }, {
          navText: this.$t('view_footer.career_text'),
          openBlank: '_blank',
          externalLink: this.$t('view_footer.career_link'),
        }, {
          navText: this.$t('view_footer.press_text'),
          openBlank: '_blank',
          externalLink: this.$t('view_footer.press_link'),
        }, {
          navText: this.$t('view_footer.data_protection_text'),
          openBlank: '_blank',
          externalLink: this.$t('view_footer.data_protection_link'),
        }, {
          navText: this.$t('view_footer.impressum_text'),
          routeName: 'ImpressumView',
        }],
      }, {
        titleText: this.$t('view_footer.need_help_text'),
        classeNames: ['mr-34', 'whitespace-no-wrap', 'min-w-150'],
        navItems: [{
          navText: this.$t('view_footer.contact_text'),
          openBlank: '_blank',
          externalLink: this.$t('view_footer.contact_link'),
        }, {
          navText: 'FAQ',
          openBlank: '_blank',
          externalLink: `https://www.swisskrono.com/ch-${this.$i18n.locale}/faq/#`,
        }, {
          navText: 'Downloads',
          openBlank: '_blank',
          externalLink: this.$t('view_footer.downloads_link'),
        }, {
          navText: 'www.swisskrono.com',
          externalLink: `http://www.swisskrono.com/de-${this.$i18n.locale}/`,
          classeNames: ['mt-22'],
        }],
      }];
    },
  },
};
</script>


<style lang="scss" scoped>
</style>
